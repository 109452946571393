import { getUUID } from '../../../utility';

export default {
  'adalat_omar': {
    id: getUUID(),
    name: 'Adalat Omar',
    phoneNumber: '(604) 512-5617‬',
    email: 'adalat.dance@gmail.com'
  },
  "dancin_stars": {
      id: getUUID(),
      name: "Dancin' Stars",
      phoneNumber: '(604) 524-3516',
      email: 'dancinstarsbc@gmail.com',
      website: 'http://dancinstarsbc.com'
  },
  'danya': {
    id: getUUID(),
    name: 'Assistant: Danya',
    email: 'academy@adalatdance.com'
  }
};
